import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import router from '@/router'
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useCountryList() {
  // Use toast

  const toast = useToast();

  // if (Designations) emit('addDesignation')
  const refFamiliesListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", label: "البلد", sortable: true },

    
    { key: "actions" ,label: "الخيارات"},
  ];
  const tableColumnsGovernate = [
    { key: "name", label: "المحافظة", sortable: true },

    
    { key: "actions" ,label: "الخيارات"},
  ];
  const tableColumnsCities = [
    { key: "name", label: "المنطقة", sortable: true },

    
    { key: "actions" ,label: "الخيارات"},
  ];
  const tableColumnsVillages = [
    { key: "name", label: "الناحية", sortable: true },

    
    { key: "actions" ,label: "الخيارات"},
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refFamiliesListTable.value
      ? refFamiliesListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refFamiliesListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

 
  const fetchCountries = (ctx, callback) => {
    store
      .dispatch("app-location/fetchCountry")
      .then((response) => {
    
        const { data, total } = response.data;
       
        callback(data);
       
     
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Country list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const fetchGovernate = (ctx, callback) => {
 
    store
      .dispatch("app-location/fetchGovernate",{ id: router.currentRoute.params.id })
      .then((response) => {
    
        const { data, total } = response.data;
    
        callback(data);
        totalUsers.value = 10;

     
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Country list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const fetchCities = (ctx, callback) => {
  
    store
      .dispatch("app-location/fetchCities",{ id: router.currentRoute.params.id })
      .then((response) => {
   
        const { data, total } = response.data;
        //console.log(response.data)
        callback(data);
        totalUsers.value = 10;

     
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Country list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const fetchVillages = (ctx, callback) => {

    store
      .dispatch("app-location/fetchVillages",{ id: router.currentRoute.params.id })
      .then((response) => {
    
        const { data, total } = response.data;
   
        callback(data);
        totalUsers.value = 10;

     
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Villages list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchCountries,
    fetchGovernate,
    fetchCities,
    fetchVillages,
    tableColumns,
    perPage,
    currentPage,
    tableColumnsGovernate,
    tableColumnsCities,
    tableColumnsVillages,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refFamiliesListTable,

    refetchData,
  };
}
